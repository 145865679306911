import Layout from "../components/common/Layout";
import Link from "next/link";


export default function Home() {
  const seo = {
    title: 'Home',
    description: 'An educational competition for every student to test their unique capability in their favorite subject(s) and win gifts because we believe every child is a winner! Putting Education First. Prepares you for Success In Life'
  }


  return (
    <Layout seo={seo}>
      <div>
        <section>
          <div className="text-white py-20 px-6 lg:py-32 lg:px-24 relative">
            <div style={{ zIndex: -1 }} className="bg-home-bg-mobile bg-center sm:bg-home-bg h-full background-filter bg-cover lg:bg-cover bg-no-repeat w-full absolute top-0 left-0"></div>
            <div style={{ zIndex: 1 }} className="">
              <h1 className="font-extrabold leading-10 text-2xl sm:text-4xl lg:text-5xl xl:text-7xl">
                EVERY CHILD<br />
                IS A WINNER
              </h1>
              <p className="mt-4 mb-16 sm:mt-6 lg:mt-8 lg:mb-20 text-white">Join edulethics students for this year...</p>
              <Link href="/signup">
                <a className="bg-primary font-semibold text-white py-4 lg:py-5 px-10 lg:px-16 rounded-md">
                  Register now
                </a>
              </Link>
            </div>
          </div>
        </section>
        <section>
          <div className="flex flex-col-reverse lg:grid lg:grid-cols-2 lg:items-center lg:pl-8 xl:pl-24">
            <div className="flex flex-col items-center lg:block py-11 lg:py-24">
              <h2 className="text-primary text-center lg:text-left font-semibold mb-3 text-2xl lg:text-4xl xl:text-5xl">About</h2>
              <h2 className="text-primary text-center lg:text-left font-extrabold text-3xl lg:text-5xl xl:text-6xl">EDULETHICS</h2>
              <p className="text-grey text-center lg:text-left mt-6 mb-6 lg:mb-11 lg:mx-0 w-9/10 sm:w-3/4 lg:w-3/4 xl:w-7/10 mx-auto">
                An educational competition for every student to test their unique capability in their favorite subject(s) and win gifts because we believe every child is a winner!
              </p>
              <Link href="/about">
                <a className="bg-primary font-semibold text-white py-3 lg:py-4 px-10 lg:px-20 rounded-xl">
                  Learn more
                </a>
              </Link>
            </div>
            <div className="mt-20 mb-8 lg:my-0">
              <img loading="lazy" className="w-9/10 mx-auto sm:w-3/5 md:h-3/4 lg:w-4/5 lg:h-full lg:mx-auto" draggable={false} alt="about us" src="/img/illustrations/about.webp" />
            </div>
          </div>
        </section>
        <section className="my-16 lg:my-32">
          <div className="flex flex-col lg:grid lg:grid-cols-3 mx-7 space-y-20 lg:space-y-0 lg:mx-12 xl:mx-14 lg:gap-8">
            <div className="box-shadow flex flex-col mx-auto sm:w-1/2 sm:mx-auto lg:w-auto space-y-2 items-center text-center px-2 py-8 lg:px-10 lg:pt-14 lg:pb-10 rounded-2xl">
              <div><img loading="lazy" src="/img/mortarboard.svg" alt="scholar" className="w-10 sm:w-12 lg:w-16" /></div>
              <div>
                <h2 className="text-lg lg:text-2xl font-bold text-primary lg:mb-4" >What is <br /> EDULETHICS?</h2>
                <p className="text-grey text-sm mx-3 mt-5 lg:mx-auto">
                  An educational competition program for all upper primary and secondary school students from the ages 9-16 years old.  It is also a place to think, write, talk, and have fun!
                </p>
              </div>
            </div>
            <div className="box-shadow flex flex-col mx-auto sm:w-1/2 sm:mx-auto lg:w-auto space-y-2 items-center text-center px-2 py-8 lg:px-10 lg:pt-14 lg:pb-10 rounded-2xl">
              <div><img loading="lazy" src="/img/question-mark.svg" alt="edulethics aim" className="w-10 sm:w-12 lg:w-16" /></div>
              <div>
                <h2 className="text-lg lg:text-2xl font-bold text-primary lg:mb-4" >What is <br /> EDULETHICS aim?</h2>
                <p className="text-grey text-sm mx-3 mt-5 lg:mx-auto">
                  In a system where Education is charged with building the leaders of tomorrow, Edulethics aims to encourage these young students with incentives while helping them follow their passion and hone their uniqueness.
                </p>
              </div>
            </div>
            <div className="box-shadow flex flex-col mx-auto sm:w-1/2 sm:mx-auto lg:w-auto space-y-2 items-center text-center px-2 py-8 lg:px-10 lg:pt-14 lg:pb-10 rounded-2xl">
              <div><img loading="lazy" src="/img/target.svg" alt="edulethics aim" className="w-10 sm:w-12 lg:w-16" /></div>
              <div>
                <h2 className="text-lg lg:text-2xl font-bold text-primary lg:mb-4" >How does <br /> EDULETHICS works?</h2>
                <p className="text-grey text-sm mx-3 mt-5 lg:mx-auto">
                  First, you register, chat with other students online, choose your subject(s), get your exam date, take the exam, check the result, win amazing gifts!
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-milky-blue py-10">
          <div className="text-center mt-14 lg:mt-20 lg:mb-96">
            <div className="mx-1 lg:mx-0">
              <h2 className="text-4xl sm:text-6xl lg:text-8xl text-gray-200 font-extrabold">Categories</h2>
              <h2 className="text-primary font-extrabold text-base sm:text-2xl lg:text-2xl xl:text-3xl relative bottom-4 sm:bottom-5 lg:bottom-7">Categories</h2>
            </div>
            <div className="mt-16 lg:mt-24 grid gap-9 sm:gap-0 lg:gap-9 grid-cols-1 sm:grid-cols-3 lg:mx-32 xl:mx-40">
              <div className="flex flex-col items-center rounded-2xl p-0 bg-primary w-4/5 lg:w-full mx-auto">
                <div className="overflow-hidden relative rounded-xl">
                  <img src="/img/student-1.webp" className="relative bottom-2 w-full scale-on-hover cursor-pointer" alt="primary school student" />
                </div>
                <div className="bg-primary rounded-2xl space-y-1 pl-3 py-5 sm:pl-4 lg:py-4 lg:pl-5 w-full text-white text-left">
                  <h3 className="font-extrabold text-base lg:text-xl">Primary Schools</h3>
                  <div className="text-sm lg:text-base">
                    <p>Youngest Classes</p>
                    <p>(Basic 4 - Basic 5/6)</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center rounded-2xl p-0 bg-primary w-4/5 sm:w-3/4 lg:w-full mx-auto">
                <div className="overflow-hidden relative rounded-xl">
                  <img src="/img/student-2.webp" className="relative bottom-2 w-full scale-on-hover cursor-pointer" alt="junior school student" />
                </div>
                <div className="bg-primary rounded-2xl space-y-1 pl-3 py-5 sm:pl-4 lg:py-4 lg:pl-5 w-full text-white text-left">
                  <h3 className="font-extrabold text-base lg:text-xl">Junior Secondary Schools</h3>
                  <div className="text-sm lg:text-base">
                    <p>Mid Classes</p>
                    <p>(JSS 1 - JSS 3) from age</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center rounded-2xl p-0 bg-primary w-4/5 sm:w-3/4 lg:w-full mx-auto">
                <div className="overflow-hidden relative rounded-xl">
                  <img src="/img/student-3.webp" className="relative bottom-2 w-full scale-on-hover cursor-pointer" alt="senior school student" />
                </div>
                <div className="bg-primary rounded-2xl space-y-1 pl-3 py-5 sm:pl-4 lg:py-4 lg:pl-5 w-full text-white text-left">
                  <h3 className="font-extrabold text-base lg:text-xl">Senior Secondary Schools</h3>
                  <div className="text-sm lg:text-base">
                    <p>Senior Classes</p>
                    <p>(SSS 1 - SSS 3)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mt-20 lg:mt-28 lg:mb-20">
            <h2 className="text-4xl sm:text-6xl lg:text-8xl text-gray-200 font-extrabold">EDULETHICS</h2>
            <h2 className="text-primary font-extrabold text-base sm:text-2xl lg:text-2xl xl:text-3xl relative bottom-4 sm:bottom-5 lg:bottom-7">HOW <b className="lg:text-3xl xl:text-4xl">EDULETHICS</b> WORKS</h2>
            {/* <div style={{zIndex: 1}} className="hidden lg:block lg:mx-44 relative top-36  border-primary border"></div> */}
            <div className="mt-24 lg:mx-16 grid gap-9 grid-cols-1 lg:grid-cols-4">
              <div className="flex flex-col space-y-2 lg:space-y-3 lg:w-52 lg:mx-auto items-center text-center">
                <div className="">
                  <img loading="lazy" className="w-20 lg:w-24" src="/img/register.svg" alt="register" />
                </div>
                <div>
                  <h2 className="text-xl font-bold text-primary">Register Now</h2>
                  <p className="text-sm text-grey w-9/10 mx-auto mt-2">Register now to be eligible to take the test</p>
                </div>
              </div>
              <div className="flex flex-col space-y-2 lg:space-y-3 lg:w-52 lg:mx-auto items-center text-center">
                <div>
                  <img loading="lazy" className="w-20 lg:w-24" src="/img/select-subject.svg" alt="select subject" />
                </div>
                <div>
                  <h2 className="text-xl font-bold text-primary">Select Your Subject</h2>
                  <p className="text-sm text-grey lg:w-9/10 mx-auto mt-2">Select your desired subject to take the test</p>
                </div>
              </div>
              <div className="flex flex-col space-y-2 lg:space-y-3 lg:w-52 lg:mx-auto items-center text-center">
                <div>
                  <img loading="lazy" className="w-20 lg:w-24" src="/img/get-prepared.svg" alt="get prepared" />
                </div>
                <div>
                  <h2 className="text-xl font-bold text-primary">Get Prepared</h2>
                  <p className="text-sm text-grey w-3/5 sm:w-2/3 lg:w-9/10 mx-auto mt-2">Do research in your favourite subject. Find the recommended resources under each subject.</p>
                </div>
              </div>
              <div className="flex flex-col space-y-2 lg:space-y-3 lg:w-52 lg:mx-auto items-center text-center">
                <div>
                  <img loading="lazy" className="w-20 lg:w-24" src="/img/take-challenge.svg" alt="take the challenge" />
                </div>
                <div>
                  <h2 className="text-xl font-bold text-primary">Take The Challenge</h2>
                  <p className="text-sm text-grey w-9/10 mx-auto mt-2">Take the challenge online and get your prize!</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="my-16 lg:my-32 xl:my-36">
          <div id="partners" className="text-center">
            <h2 className="text-5xl sm:text-6xl lg:text-8xl text-gray-200 font-extrabold">Partner</h2>
            <h2 className="text-primary font-extrabold text-base sm:text-2xl lg:text-2xl xl:text-3xl relative bottom-4 sm:bottom-5 lg:bottom-6">Partner with EDULETHICS</h2>
          </div>
          <div className="mt-10 grid gap-10 grid-cols-1 sm:grid-cols-2 sm:gap-0 lg:gap-8 lg:my-20 lg:mx-44 xl:mx-80">

            <div className="flex flex-col items-center text-center box-shadow rounded-xl w-4/5 sm:w-3/4 lg:w-full mx-auto">
              <div className="overflow-hidden rounded-xl">
                <img loading="lazy" alt="corporate - sponsor" className="scale-on-hover cursor-pointer" src="/img/corporate-sponsor.webp" />
              </div>
              <div className="py-8">
                <h3 className="text-primary font-semibold text-2xl sm:text-xl lg:text-2xl">Partner as a <br /> <b className="font-extrabold lg:text-3xl">Prize Sponsor</b></h3>
              </div>
            </div>
            <div className="flex flex-col items-center text-center box-shadow rounded-xl w-4/5 sm:w-3/4 lg:w-full mx-auto">
              <div className="overflow-hidden rounded-xl">
                <img loading="lazy" alt="government - council" className="scale-on-hover cursor-pointer" src="/img/government-council.webp" />
              </div>
              <div className="py-8 px-2">
                <h3 className="text-primary font-semibold text-2xl sm:text-xl lg:text-2xl">Partner as a <br /> <b className="font-extrabold lg:text-3xl">Corporate Sponsor</b></h3>
              </div>
            </div>
          </div>
        </section>
        <section className="my-40 lg:my-32 xl:my-36 overflow-hidden">
          <div className="text-center my-8 lg:my-3 lg:mx-1">
            <h2 className="text-5xl sm:text-6xl lg:text-8xl text-gray-200 font-extrabold">Student's Feedback</h2>
            <h2 className="text-primary font-extrabold text-base sm:text-2xl lg:text-2xl xl:text-3xl relative bottom-4 sm:bottom-5 lg:bottom-6">Student's Feedback</h2>
            <p className="text-grey font-semibold text-sm lg:text-base mx-2 sm:mx-0">Here's  what students are saying about this program!</p>
          </div>

          <div className="slides text-sm lg:text-base flex flex-row flex-nowrap sm:overflow-x-auto mx-5 lg:mx-20 xl:mx-56 my-10">
            <div className="w-600 lg:w-700 xl:w-800 mx-auto pb-5 slides flex flex-row space-x-5 flex-nowrap overflow-x-auto">
              <div id="feedback1" className="w-600 lg:w-700 xl:w-800 mx-auto space-x-5 flex flex-row flex-nowrap">
                <div className="w-300 lg:w-350 xl:w-400 box-shadow-hover flex flex-col items-center text-center rounded-lg bg-grey-3 space-y-5 lg:space-y-8 px-3 py-6 lg:px-5 xl:px-10 lg:py-6">
                  <div>
                    <p>
                      I was excited hearing that we can take any subject of our choice, finally an exam where I can choose the subject I am really good at, really excited about joining edulethics!
                    </p>
                  </div>
                  <div>
                    <div>
                      <img loading="lazy" alt="user" className="mx-auto w-12 h-12 rounded-circle" src="/img/user.webp" />
                    </div>
                    <div className="my-2">
                      <h3 className="font-bold text-sm lg:text-base">Kolawole Yusuf</h3>
                      <h3 className="font-semibold text-xl lg:text-2xl">JSS 3 Student</h3>
                      <h4 className="text-xs font-semibold">Lagos</h4>
                    </div>
                  </div>
                </div>
                <div className="w-300 lg:w-350 xl:w-400 box-shadow-hover flex flex-col items-center text-center rounded-lg bg-grey-3 space-y-5 lg:space-y-8 px-3 py-6 lg:px-5 xl:px-10 lg:py-6">
                  <div>
                    <p>
                      All my friends and I can't wait to take the exam, knowing that we will win something helps reduce the tension a  little bit. But I'm positive I will do well.
                    </p>
                  </div>
                  <div>
                    <div>
                      <img loading="lazy" alt="user" className="mx-auto w-12 h-12 rounded-circle" src="/img/student-2.webp" />
                    </div>
                    <div className="my-2">
                      <h3 className="font-bold text-sm lg:text-base">Ifunanya Okoye</h3>
                      <h3 className="font-semibold text-xl lg:text-2xl">SS 2 Student</h3>
                      <h4 className="text-xs font-semibold">Ibadan</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}
